import React from 'react'
import { Col, Row } from 'antd'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './AboutUsContent.css'

export default () => {
    const data = useStaticQuery(graphql`
    query aboutUsQuery {
      file(relativePath: {eq: "AboutUs_images/churchhistory.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2000, quality: 100){
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
    return (
        <div>
            <BackgroundImage fluid={data.file.childImageSharp.fluid}>
                <div className='AboutUsHeader'>
                    <h1>教 會 歷 史</h1>
                </div>
            </BackgroundImage>
            <div className='ChurchHistoryBackground'>
                <div className='ChurchHistory'>
                    <p>本教會創立於一九八四年九月十五日，原名「中國基督徒團契」（Chinese Christian Fellowship）。</p>
                    <p>一九九四年六月十五日改名「中華福音教會」（Chinese Gospel Church）。 成立初，僅十余人，先後借用其他教會及學校舉行主日敬拜。因無固定的場地，搬遷有七次之多，大家聯絡不易，聖工推展受限。</p>
                    <p>一九九三年十二月十五日，神賜給我們現今青少年聚會的樓房，主將得救的人，天天加給我們。原有教堂已不敷應用， 乃籌劃擴建新堂，於二零零四年春開始在新堂聚會。並於一九九八年創立家庭小組，分別在各地弟兄姐妹家中讀經禱告，彼此交通接納，廣傳福音。</p>
                    <p>願主恩待引領，榮耀歸給至高真神。</p>
                </div>
            </div>
        </div>
    )
}