import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import ChurchHistory from '../components/AboutUs/AboutUsContent'
import GlobalFooter from '../components/Footer/GlobalFooter'


export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <ChurchHistory />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }